import { forwardRef } from 'react';
import { PiWarningCircleFill } from 'react-icons/pi';
import { Tooltip } from '@mantine/core';

type WarningProps = {
  color: string;
  hasMultipleValues: boolean;
};

const EmptyValueWarning = forwardRef<HTMLDivElement, WarningProps>(
  ({ color, hasMultipleValues }, ref) => (
    <Tooltip
      offset={13}
      maw={250}
      multiline
      label="Warning: If this field is empty or not a correct domain then the language will not appear in the language selector."
      withinPortal
      arrowSize={8}
      position="bottom"
    >
      <div ref={ref}>
        <PiWarningCircleFill
          color={color}
          cursor="pointer"
          size="16"
          style={{
            position: 'absolute',
            top: hasMultipleValues ? 12.5 : 32,
            left: -22,
          }}
        />
      </div>
    </Tooltip>
  )
);

export default EmptyValueWarning;
