import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import Delete from './xapis-wrappers/Deleter';
import { OrderResponse } from './PayInvoiceStore';
import { xapisEnv } from './service-wrappers/xapisService';

import { FetcherPromise } from './xapis-wrappers';
import useNotifyStore from './NotifyStore';

// This type is completely dependent on Digital River
export type PaymentInfo = {
  createdTime: string; // ex. "2023-02-08T18:08:49.244959Z[UTC]",
  data: {
    object: OrderResponse;
  };
  id: string; // ex. "7068943a-8668-4d1c-96b6-ba6602a2950e",
  liveMode: boolean;
  type: string; // ex. "order.accepted"
};

export type Subscription = {
  anniversary_day: number;
  anniversary_day_nice: string;
  anniversary_month: number;
  anniversary_month_abbrev: string;
  anniversary_month_name: string;
  bill_to_email: string;
  bill_to_name: string;
  billing_agreement_key: string;
  created_user: string;
  created_utc: string;
  currency_code: string;
  days_from_renewal_notice: number;
  dr_contact: string;
  dr_cookie_policy: string;
  dr_privacy_policy: string;
  dr_selling_entity_id: string;
  dr_selling_entity_name: string;
  dr_terms_conditions: string;
  invoice_file_id: string;
  is_canceled: boolean;
  last_order_utc: string;
  lastmod_user: string;
  lastmod_utc: string;
  next_month_abbrev: string;
  next_month_full: string;
  next_renewal_date: string;
  next_subscription?: Subscription;
  pay_status: string;
  payment_info: PaymentInfo | string; // Object or empty string ""
  payment_source_id: string;
  price: number;
  project_key: string;
  purchase_lang_code: string;
  sku: string;
  sku_description: string;
  sku_name: string;
  subscription_type: string;
  subscription_value: number;
  threshold_type: string;
  threshold_value: number;
};

export const defaultSubscription: Subscription = {
  anniversary_day: 0,
  anniversary_day_nice: '',
  anniversary_month: 0,
  anniversary_month_abbrev: '',
  anniversary_month_name: '',
  bill_to_email: '',
  bill_to_name: '',
  billing_agreement_key: '',
  created_user: '',
  created_utc: '',
  currency_code: '',
  days_from_renewal_notice: 0,
  dr_contact: '',
  dr_cookie_policy: '',
  dr_privacy_policy: '',
  dr_selling_entity_id: '',
  dr_selling_entity_name: '',
  dr_terms_conditions: '',
  invoice_file_id: '',
  is_canceled: false,
  last_order_utc: '',
  lastmod_user: '',
  lastmod_utc: '',
  next_month_abbrev: '',
  next_month_full: '',
  next_renewal_date: '',
  pay_status: '',
  payment_info: '',
  payment_source_id: '',
  price: 0,
  project_key: '',
  purchase_lang_code: '',
  sku: '',
  sku_description: '',
  sku_name: '',
  subscription_type: '',
  subscription_value: 0,
  threshold_type: '',
  threshold_value: 0,
};

export type PaySubscriptionStore = {
  totalRows: number;
  subscriptions: Subscription[];
  setTotalRows: (rows: number) => void;
  setSubscriptions: (subscriptions: Subscription[]) => void;
  fetchSubscriptions: (
    payKey: string,
    params: object,
    projectKey: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
  cancelSubscription: (
    user: User,
    payKey: string,
    projectKey: string,
    failed: { errorMessage?: string; action?: () => void },
    success: () => void,
    message: string,
    cleanup?: () => void
  ) => void;
  loading: boolean;
};

export const usePaySubscriptionStore = create<PaySubscriptionStore>()(
  (set, get) => ({
    totalRows: 0,
    subscriptions: [],
    loading: false,
    setSubscriptions: (subscriptions) => set(() => ({ subscriptions })),
    setTotalRows: (totalRows) => set(() => ({ totalRows })),
    fetchSubscriptions: (payKey, params, projectKey, failed) => {
      const { loading } = get();
      if (loading) return;

      set({ loading: true });
      Get(
        `Pay/Subscription/${payKey}/${projectKey || ''}`,
        ({
          data: { subscriptions, rows_total },
        }: {
          data: { subscriptions: Subscription[]; rows_total: number };
        }) => {
          set({ subscriptions });
          set({ totalRows: rows_total });
        },
        {
          errorMessage: failed.errorMessage || '',
          action: () => {
            set({ subscriptions: [] });
            failed.action && failed.action();
          },
        },
        params,
        () => set({ loading: false })
      );
    },
    cancelSubscription: (
      user,
      payKey,
      projectKey,
      failed,
      success,
      message
    ) => {
      const { label } = xapisEnv.getHost;
      const { sendContactUsMessage } = useNotifyStore.getState();

      const contactUsMessage = {
        app: 'GlobalLinkGO',
        domain: label,
        firstName: user.first_name || '',
        lastName: user.last_name || '',
        company: '',
        email: user.email || '',
        phoneNumber: user.phone,
        how: '',
        source: 'Dashboard Cancellation Form',
        message,
      };

      Delete(
        `Pay/Subscription/${payKey}/${projectKey}`,
        {},
        () => {
          sendContactUsMessage(contactUsMessage);
          success();
        },
        {
          errorMessage: failed.errorMessage || '',
          action: () => failed.action && failed.action(),
        }
      );
    },
  })
);

export const fetchSubscriptionsPromise = (
  payKey: string,
  projectKey: string
) => {
  return FetcherPromise(`Pay/Subscription/${payKey}/${projectKey}`);
};

export default usePaySubscriptionStore;
