import React from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  ScrollArea,
  Text,
  useMantineTheme,
} from '@mantine/core';
import { getDeploymentTargets } from '../utils';
import { MdInfo } from 'react-icons/md';
import FolderMethodTooltip from './FolderMethodTooltip';
import { useDeploymentContext } from '../DeploymentContextProvider';

const DefaultFolderMethod = () => {
  const { colors } = useMantineTheme();
  const { loading, targets = [] } = useDeploymentContext();
  const deploymentTargets = getDeploymentTargets(targets);

  return (
    <Card
      h={225}
      p={0}
      style={{ boxShadow: 'none' }}
      opacity={loading ? 0.5 : 1}
    >
      <Flex h="100%" pl="1.5rem" py="0.5rem">
        <Box w="20%" pt="1.5rem" pr="0.5rem">
          <Text pos="relative" fw={600} display="inline-flex">
            Language&nbsp;
            <FolderMethodTooltip>
              <Box>
                <MdInfo fill={colors.icon[5]} size={20} />
              </Box>
            </FolderMethodTooltip>
          </Text>
        </Box>
        <Box w="80%" pl="0.5rem">
          <ScrollArea
            h="100%"
            pr="1rem"
            scrollbarSize={22}
            type="always"
            offsetScrollbars
            className="scrollArea"
          >
            {deploymentTargets.map(
              (
                { target_lang_name = '', deployment_value = [] },
                targetIndex
              ) => {
                const isLastIndex =
                  targetIndex === deploymentTargets.length - 1;
                const hasMultipleValues = deployment_value.length > 1;

                return (
                  <Box key={targetIndex}>
                    <Flex py={hasMultipleValues ? '1.15rem' : 0}>
                      <Flex
                        w="35%"
                        h={hasMultipleValues ? '2.65rem' : '5rem'}
                        align="center"
                        pr="1rem"
                      >
                        <Text truncate>{target_lang_name}</Text>
                      </Flex>
                      <Flex
                        w="50%"
                        direction="column"
                        rowGap="0.5rem"
                        justify={hasMultipleValues ? 'flex-start' : 'center'}
                        px="1rem"
                      >
                        {deployment_value.map((value: string, valueIndex) => (
                          <Flex
                            key={`${targetIndex}-${value}-${valueIndex}`}
                            h={hasMultipleValues ? '2.65rem' : '5rem'}
                            align="center"
                          >
                            <Text truncate>{value}</Text>
                          </Flex>
                        ))}
                      </Flex>
                    </Flex>
                    {!isLastIndex && (
                      <Divider
                        size="xs"
                        orientation="horizontal"
                        color="divider.1"
                      />
                    )}
                  </Box>
                );
              }
            )}
          </ScrollArea>
        </Box>
      </Flex>
    </Card>
  );
};

export default DefaultFolderMethod;
