import React, { useEffect, useState, useContext } from 'react';
import { Box, Button, Checkbox, Flex, Popover, Title } from '@mantine/core';
import { SegmentEditorContext } from '../../context/SegmentEditorProvider';
import { DatePickerInput } from '@mantine/dates';
import { useSegmentsStateActions } from '../../store/SegmentsState';
// import { useEditorContextAPI } from '../../store/EditorStore';
import {
  FilterBy,
  FilterOption,
  Filters,
  filters,
  useDashboardActions,
  useFilters,
  useFiltersBuffer,
  useFiltersOptions,
} from '../../store/DashboardStore';

import { FilterIcon } from '../../icons/ActionIcons';
import { useEditorColors } from '../../theme/useEditorColors';
import classes from './FilterSegments.module.css';

const filtersLabels: { [filter in Filters]: string } = {
  status: 'Status',
  source: 'Translation Source',
  type: 'Type',
};

type Props = {
  filterCount: (filters: FilterBy) => number;
};

export const FilterSegments = ({ filterCount }: Props) => {
  const colors = useEditorColors();

  const filtersOptions = useFiltersOptions();
  const filtersBuffer = useFiltersBuffer();
  const { setFilterBy, setFiltersBuffer, handleFilterSelection } =
    useDashboardActions();
  const filterBy = useFilters();
  const { setSelected } = useSegmentsStateActions();
  const { onResetFilter } = useContext(SegmentEditorContext).contextAPI || {};
  const [showFilters, setShowFilters] = useState(false);
  const allAutomaticTranslations = !filtersOptions.source?.find(
    (o) => o.label === 'Human'
  );
  const disableDateFilter =
    allAutomaticTranslations ||
    (!!filtersBuffer.source.length && !filtersBuffer.source.includes('Human'));

  function handleDateSelection(dates: [Date | null, Date | null]) {
    setFiltersBuffer({ ...filtersBuffer, date: dates });
  }

  const handleApplyFilters = () => {
    setFilterBy({ ...filtersBuffer });
    setShowFilters(false);
  };

  const handleClearFilters = () => {
    // setSelected('');
    setFiltersBuffer({ source: [], status: [], type: [], date: [null, null] });
    if (typeof onResetFilter === 'function') onResetFilter();
  };

  useEffect(() => {
    // Reset filtersBuffer when filters are reset (e.g. when blocks are changed)
    if (JSON.stringify(filterBy) === JSON.stringify(filtersBuffer)) return;
    setFiltersBuffer({ ...filterBy });
  }, [filterBy]);

  const segmentsCount = filterCount(filtersBuffer);
  const countLabel = segmentsCount > 99 ? '99+' : segmentsCount;

  const FilterName = ({ label }: { label: string }) => (
    <Title order={6} pt={8}>
      {label}
    </Title>
  );

  return (
    <Popover
      withinPortal
      position="top-end"
      opened={showFilters}
      onChange={setShowFilters}
      closeOnEscape
      shadow="md"
      arrowSize={15}
      arrowOffset={20}
      offset={5}
    >
      <Popover.Target>
        <Flex
          align="center"
          onClick={() => setShowFilters(!showFilters)}
          bg={showFilters ? colors.filterMenu.button : 'transparent'}
          className={classes.filterButton}
        >
          <FilterIcon />
        </Flex>
      </Popover.Target>
      <Popover.Dropdown className={classes.filterDropdown}>
        <>
          <Box style={{ borderRadius: 15 }} px={20} py={12} bg="#FFFFFF">
            {filters.map((filter) => {
              const options: FilterOption[] = filtersOptions[filter];
              return (
                <Box key={filter}>
                  <FilterName label={filtersLabels[filter] || filter} />
                  {options.map(({ value, label, count }) => {
                    const isChecked =
                      filtersBuffer[filter as Filters]?.includes(value);
                    return (
                      <Checkbox
                        key={value}
                        c="blue.9"
                        label={`${label} (${count})`}
                        checked={isChecked}
                        disabled={options.length === 1}
                        onChange={() =>
                          handleFilterSelection(filter as Filters, value)
                        }
                      />
                    );
                  })}
                </Box>
              );
            })}
            <FilterName label="Date" />
            <DatePickerInput
              classNames={{
                label: classes.datePickerLabel,
                input: classes.datePickerInput,
              }}
              disabled={disableDateFilter}
              type="range"
              size="xs"
              label="Filter by translation date(s)"
              // placeholder="Pick dates range"
              allowSingleDateInRange
              clearable
              value={filtersBuffer.date}
              onChange={handleDateSelection}
              maxDate={new Date()}
              popoverProps={{ position: 'top' }}
            />
          </Box>
          <Flex w="100%" p={10} gap={10}>
            <Button variant="outline" onClick={handleClearFilters}>
              Clear All
            </Button>
            <Button
              onClick={handleApplyFilters}
              disabled={!segmentsCount}
              styles={{
                label: {
                  flexGrow: 1,
                  width: '100%',
                  gap: 5,
                  justifyContent: 'space-between',
                },
              }}
              style={{
                flexGrow: 1,
              }}
            >
              <Box>Apply</Box>
              <Box>{`(${segmentsCount})`}</Box>
            </Button>
          </Flex>
        </>
      </Popover.Dropdown>
    </Popover>
  );
};
