export type UnsavedItem = {
  target: Jliff[] | null;
  setToLive: boolean;
};
export interface UnsavedSegment extends UnsavedItem {
  segment_hash: string;
}
export class SList {
  list: { [hash: string]: UnsavedItem };

  constructor() {
    this.list = {};
  }

  get length() {
    return Object.keys(this.list).length;
  }

  get segments() {
    const array: UnsavedSegment[] = [];
    Object.keys(this.list).forEach((hash) => {
      array.push({
        segment_hash: hash,
        target: this.list[hash].target,
        setToLive: this.list[hash].setToLive,
      });
    });
    return array;
  }

  setToLive(sHash: string, setToLive: boolean = false) {
    if (this.has(sHash)) {
      if (setToLive || this.list[sHash].target) {
        // If it exists with target or setToLive is true, update setToLive
        this.list[sHash] = { ...this.list[sHash], setToLive };
      } else {
        // If it exists without target and setToLive is false, remove from list
        this.remove(sHash);
      }
    } else if (setToLive) {
      // IF it doesn't exist and setToLive is true, add to list with target = null
      this.add(sHash, null, setToLive);
    }
  }

  setTarget(sHash: string, target: Jliff[] | null) {
    if (!this.has(sHash)) this.add(sHash, target);
    this.list[sHash] = { ...this.list[sHash], target };
  }
  getTarget(sHash: string) {
    return this.list[sHash]?.target || null;
  }

  add(sHash: string, target: Jliff[] | null, setToLive: boolean = false) {
    if (!sHash || this.has(sHash)) return; // invalid hash or already exists
    this.list[sHash] = { target, setToLive };
  }

  remove(hash: string) {
    delete this.list[hash];
  }

  has(hash: string) {
    return Object.keys(this.list).includes(hash);
  }

  isModified(hash: string) {
    return this.has(hash) && this.list[hash].target;
  }

  get(hash: string) {
    return this.list[hash];
  }

  reset() {
    this.list = {};
  }
}
