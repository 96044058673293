import { AxiosPromise } from 'axios';
import { http } from './http';
import { segmenetEditorGlobals } from '../context/SegmentEditorProvider';

export const Xapis = {
  User: {
    get: async (key: string) => http.get(`/User/${key}`),
  },
  Project: {
    get: async (uKey: string, projectKey: string) =>
      http.get(`/Project/${projectKey}?user_key=${uKey}`),
  },
  Glossary: {
    get: async (uKey: string, tKey: string) =>
      http.get(`/Glossary/${tKey}?user_key=${uKey}`),
  },
  ProjectTranslation: {
    get: async (uKey: string, projectKey: string, tKey?: string) => {
      const path = tKey ? `${projectKey}/${tKey}` : `${projectKey}`;
      return http.get(`/ProjectTranslation/${path}?user_key=${uKey}`);
    },
  },

  BlockSegmentData: {
    get: async (tKey: string, urlHash: string) =>
      http.get(`/BlockSegmentData/${tKey}/${urlHash}`),
    post: async (data: BlockData[], tKey: string, urlHash?: string) =>
      http.post(`/BlockSegmentData/${tKey}/${urlHash || ''}`, data),
  },
  Segment: {
    get: async (tKey: string): AxiosPromise<SegmentAPIResponse> =>
      http.get(`/Segment/${tKey}?ignore_backfill=1&limit=0`),
  },
  SegmentContent: {
    post: async (
      tKey: string,
      uKey: string,
      data: SegmentContentData[]
    ): AxiosPromise<SegmentContentResponse> => {
      const { version, appName } = segmenetEditorGlobals;
      return http.post(
        `/SegmentContent/${tKey}?user_key=${uKey}&version=${appName} ${version}`,
        data
      );
    },
    delete: async (tKey: string, uKey: string, sHash: string, is_staging = 0) =>
      http.delete(`/SegmentContent/${tKey}?user_key=${uKey}`, {
        data: [{ segment_hash: sHash, user_key: uKey, is_staging: is_staging }],
      }),
  },
  SegmentTranslationHistory: {
    get: async (uKey: string, tKey: string, sHash: string) =>
      http.get(`/SegmentTranslationHistory/${tKey}/${sHash}?user_key=${uKey}`),
  },
};
