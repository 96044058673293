import React from 'react';
import { AuthProvider } from 'react-oidc-context';
import { default as oidcStagingConfig } from './oidcConfig';
import { default as oidcProdConfig } from './oidcProdConfig';
import { LIVE_XPAY_URL } from 'helpers';

const AuthWrapper = ({ children }: { children: React.ReactNode }) => {
  const oidcConfig =
    window.location.origin === LIVE_XPAY_URL
      ? oidcProdConfig
      : oidcStagingConfig;

  const {
    client_id,
    redirect_uri,
    response_type,
    scope,
    authority,
    post_logout_redirect_uri,
    automaticSilentRenew,
    silent_redirect_uri,
    filterProtocolClaims,
    loadUserInfo,
  } = oidcConfig;

  sessionStorage.setItem('TOKEN_KEY', `oidc.user:${authority}:${client_id}`);

  return (
    <AuthProvider
      client_id={client_id}
      redirect_uri={redirect_uri}
      response_type={response_type}
      scope={scope}
      authority={authority}
      post_logout_redirect_uri={post_logout_redirect_uri}
      automaticSilentRenew={automaticSilentRenew}
      silent_redirect_uri={silent_redirect_uri}
      filterProtocolClaims={filterProtocolClaims}
      loadUserInfo={loadUserInfo}
    >
      {children}
    </AuthProvider>
  );
};

export default AuthWrapper;
