import { isValidDomain } from 'helpers';
import { TbCookie, TbSectionSign } from 'react-icons/tb';
import { Text, MantineThemeColors, Box } from '@mantine/core';
import { FaRegFolder } from 'react-icons/fa6';
import { RiGlobalLine } from 'react-icons/ri';
import { UseFormGetValues, UseFormSetValue } from 'react-hook-form';

export const COOKIE = 'cookie';
export const FOLDER = 'folder';
export const DOMAIN = 'domain';
export const QUERY = 'query';

export type Method =
  | typeof COOKIE
  | typeof FOLDER
  | typeof DOMAIN
  | typeof QUERY;

export type SelectedMethod = Method | Omit<Method, string>;

type DeploymentMethods = {
  [method in Method]: {
    Icon: ({ colors }: { colors: MantineThemeColors }) => JSX.Element;
    UrlPreview: ({
      url,
      textColor,
      fontSize,
    }: {
      url: string;
      textColor?: string;
      fontSize?: string;
    }) => JSX.Element;
    description: React.ReactNode;
  };
};

export const deploymentMethods: DeploymentMethods = {
  [COOKIE]: {
    Icon: ({ colors }) => <TbCookie size={25} color={colors.icon[5]} />,
    UrlPreview: ({ url, textColor, fontSize }) => (
      <Text
        truncate
        fw={500}
        fz={fontSize || '1.15rem'}
        c={textColor || 'text3.3'}
        span
      >
        {url}
      </Text>
    ),
    description: (
      <Text fz="1.25rem">
        This method works immediately with no further work required beyond
        injecting the JavaScript snippet. However, search engines will not be
        able to detect and index your translated site.
        <br />
        <br />
        <Text fz="1.25rem" span>
          <Text fz="1.25rem" fw={700} span>
            NOTE:
          </Text>{' '}
          your source site will continue to be indexed.
        </Text>
      </Text>
    ),
  },
  [FOLDER]: {
    Icon: ({ colors }) => <FaRegFolder size={25} color={colors.icon[5]} />,
    UrlPreview: ({ url, textColor, fontSize }) => (
      <Text
        truncate
        fw={500}
        fz={fontSize || '1.15rem'}
        c={textColor || 'text3.3'}
        span
      >
        {url}
        <Text
          truncate
          fw={800}
          fz={fontSize || '1.15rem'}
          c={textColor || 'text3.3'}
          span
        >
          /fr/
        </Text>
      </Text>
    ),
    description:
      'Before you confirm switching to this deployment method, make sure you have set up the folder for each language and that the specific URL you are using for that folder works. If any of these steps have not been completed prior to switching to this method, the user will not see a translated site when switching to that language.',
  },
  [DOMAIN]: {
    Icon: ({ colors }) => <RiGlobalLine size={25} color={colors.icon[5]} />,
    UrlPreview: ({ url, textColor, fontSize }) => {
      const parsedUrl = url.replaceAll('www.', '');
      return (
        <Text
          truncate
          fw={500}
          fz={fontSize || '1.15rem'}
          c={textColor || 'text3.3'}
          span
        >
          <Text
            truncate
            fw={800}
            fz={fontSize || '1.15rem'}
            c={textColor || 'text3.3'}
            span
          >
            fr.
          </Text>
          {parsedUrl}
        </Text>
      );
    },
    description:
      'Before you confirm switching to this deployment method, make sure you own the domains you want to use for the translated sites, as well as the correct SSL certificates, and set up DNS for the additional domains as well. If any of these steps have not been completed prior to switching to this method, the translated site will not work.',
  },
  [QUERY]: {
    Icon: ({ colors }) => (
      <Box style={{ transform: 'rotate(90deg)' }}>
        <TbSectionSign size={25} color={colors.icon[5]} />
      </Box>
    ),
    UrlPreview: ({ url, textColor, fontSize }) => (
      <Text
        truncate
        fw={500}
        fz={fontSize || '1.15rem'}
        c={textColor || 'text3.3'}
        span
      >
        {url}
        <Text
          truncate
          fw={800}
          fz={fontSize || '1.15rem'}
          c={textColor || 'text3.3'}
          span
        >
          ?lang=fr
        </Text>
      </Text>
    ),
    description: (
      <Text fz="1.25rem" span>
        This method is{' '}
        <Text fz="1.25rem" c="text.4" fs="italic" span>
          not recommended
        </Text>{' '}
        as the language parameter will be tangled with query parameters that
        your site might already use for queries, the URL itself can look
        awkward, and some SEO engines ignore query strings.
      </Text>
    ),
  },
};

export type FormData = {
  deploymentValues: (string | string[])[];
  deploymentMethod?: string;
  deploymentName?: string;
  prevDeploymentName?: string;
};
export type TargetFormValue = Omit<TranslationKey, 'deployment_value'> & {
  deployment_value: string[];
};
export type FormValues = {
  targetValues: TargetFormValue[];
  deploymentMethod: string;
  deploymentName: string;
};

export const getDeploymentTargets = (
  targets: TranslationKey[]
): TargetFormValue[] => {
  return targets.map((target) => {
    const { deployment_value } = target || {};
    return { ...target, deployment_value: deployment_value.split(',') };
  });
};

export const hasValidDeploymentValues = (
  deploymentValues: string[] | string[][],
  method: Method
) => {
  switch (method) {
    case COOKIE:
    case QUERY:
      return deploymentValues.every((value) => value);
    case FOLDER:
      return deploymentValues.every((values) => {
        if (Array.isArray(values)) {
          return values.every(
            (value) =>
              value.length > 2 && value[0] === '/' && value.slice(-1) === '/'
          );
        }
        return false;
      });
    case DOMAIN:
      return deploymentValues.every((values) => {
        if (Array.isArray(values)) {
          return values.every((value) => isValidDomain(value));
        }
        return false;
      });
    default:
      return false;
  }
};

export const addDeploymentValue = (
  targetLangCode: string,
  getValues: UseFormGetValues<FormValues>,
  setValue: UseFormSetValue<FormValues>
) => {
  const values = getValues('targetValues') || [];
  const newTargetValues = values.map((target) => {
    if (target.target_lang_code === targetLangCode) {
      return {
        ...target,
        deployment_value: [...(target.deployment_value || []), ''],
      };
    }
    return target;
  });
  setValue('targetValues', newTargetValues, { shouldDirty: true });
};

export const removeDeploymentValue = (
  targetLangCode: string,
  getValues: UseFormGetValues<FormValues>,
  setValue: UseFormSetValue<FormValues>
) => {
  const values = getValues('targetValues') || [];
  const newTargetValues = values.map((target) => {
    if (target.target_lang_code === targetLangCode) {
      return {
        ...target,
        deployment_value: [...(target.deployment_value.slice(0, -1) || [])],
      };
    }
    return target;
  });
  setValue('targetValues', newTargetValues, { shouldDirty: true });
};
