import { Flex, TextInput } from '@mantine/core';
import { CloseIcon } from '../../icons/ActionIcons';
import { FilterSegments } from './FilterSegments';
import { FilterBy } from '../../store/DashboardStore';
import { MdSearch } from 'react-icons/md';
import { useEditorColors } from '../../theme/useEditorColors';
import { SearchBy } from '../../types/editor';
import classes from './SearchSegments.module.css';

type FilterProps = {
  filterCount: (filters: FilterBy) => number;
  setSearchBy: React.Dispatch<React.SetStateAction<SearchBy>>;
  searchBy: SearchBy;
};

const SearchSegments = ({
  filterCount,
  setSearchBy,
  searchBy,
}: FilterProps) => {
  const colors = useEditorColors();

  function onChange(s: string) {
    setSearchBy({ text: s });
  }

  const ClearText = () => {
    return (
      <Flex onClick={() => setSearchBy({ text: '' })}>
        <CloseIcon size={15} />
      </Flex>
    );
  };

  return (
    <TextInput
      classNames={{
        input: classes.searchInput,
        section: classes.searchRightIcon,
      }}
      leftSection={<MdSearch size={20} color={colors.searchBar.placeholder} />}
      rightSection={
        searchBy.text ? (
          <ClearText />
        ) : (
          <FilterSegments filterCount={filterCount} />
        )
      }
      placeholder="Search phrase"
      value={searchBy.text}
      onChange={(ev) => onChange(ev.currentTarget.value)}
    />
  );
};

export default SearchSegments;
