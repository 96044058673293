import React from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  ScrollArea,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import {
  FOLDER,
  FormValues,
  TargetFormValue,
  addDeploymentValue,
  getDeploymentTargets,
  hasValidDeploymentValues,
  removeDeploymentValue,
} from '../utils';
import { MdAdd, MdInfo, MdOutlineRemoveCircleOutline } from 'react-icons/md';
import FooterButtons from '../FooterButtons';
import FolderMethodTooltip from './FolderMethodTooltip';
import { useDeploymentContext } from '../DeploymentContextProvider';

const EditFolderMethod = () => {
  const { colors } = useMantineTheme();
  const {
    loading,
    setLoading,
    setShowForm,
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();
  const defaultTargetValues = getDeploymentTargets(targets);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    formState: { isDirty = false },
  } = useForm<FormValues>({
    defaultValues: {
      targetValues: defaultTargetValues,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const handleUpdate = async (data: { targetValues: TargetFormValue[] }) => {
    const { targetValues = [] } = data || {};

    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    if (!isDirty) {
      setShowForm(false);
      return;
    } else if (!hasValidDeploymentValues(deploymentValues, FOLDER)) {
      notifications.show({
        message: 'A valid Folder pattern is required per language',
      });
      return;
    } else {
      setLoading(true);

      updateDeploymentDetails({ deploymentValues })
        .then(() => {
          notifications.show({
            message: 'Successfully updated deployment details!',
          });
        })
        .finally(() => {
          setShowForm(false);
          setLoading(false);
        });
    }
  };

  return (
    <Flex direction="column" rowGap="2rem" opacity={loading ? 0.5 : 1}>
      <Card
        h={225}
        p={0}
        style={{ boxShadow: 'none' }}
        opacity={loading ? 0.5 : 1}
      >
        <Flex h="100%" pl="1.5rem" py="0.5rem">
          <Box w="20%" pt="1.5rem" pr="0.5rem">
            <Text fw={600} display="inline-flex">
              Language&nbsp;
              <FolderMethodTooltip>
                <Box>
                  <MdInfo fill={colors.icon[5]} size={20} />
                </Box>
              </FolderMethodTooltip>
            </Text>
          </Box>
          <Box w="80%" pl="0.5rem">
            <ScrollArea
              h="100%"
              pr="1rem"
              scrollbarSize={22}
              type="always"
              offsetScrollbars
              className="scrollArea"
            >
              {targetValues.map(
                (
                  {
                    id = '',
                    target_lang_code = '',
                    target_lang_name = '',
                    deployment_value = [],
                  },
                  targetIndex
                ) => {
                  const isLastIndex = targetIndex === targetValues.length - 1;
                  const hasMultipleValues = deployment_value.length > 1;

                  return (
                    <Box key={id}>
                      <Flex py={hasMultipleValues ? '1.15rem' : 0}>
                        <Flex
                          w="35%"
                          h={hasMultipleValues ? '2.65rem' : '5rem'}
                          align="center"
                          pr="1rem"
                        >
                          <Text truncate>{target_lang_name}</Text>
                        </Flex>
                        <Flex
                          w="50%"
                          direction="column"
                          rowGap="0.5rem"
                          justify={hasMultipleValues ? 'flex-start' : 'center'}
                          px="1rem"
                        >
                          {deployment_value.map((value, valueIndex) => (
                            <Flex
                              key={`${id}-${value}-${valueIndex}`}
                              h={hasMultipleValues ? '2.65rem' : '5rem'}
                              align="center"
                            >
                              <TextInput
                                maw="15rem"
                                size="md"
                                {...register(
                                  `targetValues.${targetIndex}.deployment_value.${valueIndex}`
                                )}
                                defaultValue={value}
                              />
                            </Flex>
                          ))}
                        </Flex>
                        <Flex
                          w="15%"
                          mih="5rem"
                          align={hasMultipleValues ? 'flex-end' : 'center'}
                        >
                          <Flex h="2.5rem" align="center" columnGap="0.5rem">
                            {hasMultipleValues && (
                              <Box
                                h={25}
                                style={{ cursor: 'pointer' }}
                                onClick={() =>
                                  removeDeploymentValue(
                                    target_lang_code,
                                    getValues,
                                    setValue
                                  )
                                }
                              >
                                <MdOutlineRemoveCircleOutline
                                  size={25}
                                  color={colors.icon[0]}
                                />
                              </Box>
                            )}
                            <Box
                              h={25}
                              style={{ cursor: 'pointer' }}
                              onClick={() =>
                                addDeploymentValue(
                                  target_lang_code,
                                  getValues,
                                  setValue
                                )
                              }
                            >
                              <MdAdd size={25} color={colors.icon[0]} />
                            </Box>
                          </Flex>
                        </Flex>
                      </Flex>
                      {!isLastIndex && (
                        <Divider
                          size="xs"
                          orientation="horizontal"
                          color="divider.1"
                        />
                      )}
                    </Box>
                  );
                }
              )}
            </ScrollArea>
          </Box>
        </Flex>
      </Card>
      <Divider size="xs" orientation="horizontal" color="divider.1" />
      <FooterButtons
        loading={loading}
        onCancel={() => {
          setShowForm(false);
        }}
        onSave={handleSubmit(handleUpdate)}
      />
    </Flex>
  );
};

export default EditFolderMethod;
