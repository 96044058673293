import hexToAscii from './hexToAscii';

export const hexToObject = (hexString: string) => {
  if (!hexString) {
    return {};
  }

  try {
    return JSON.parse(hexToAscii(hexString));
  } catch (error) {
    console.error(error);
    return {};
  }
};

export default hexToObject;
