export const asciiToHex = (str: string) => {
  return (
    decodeURIComponent(encodeURIComponent(str.toString()))
      .split('')
      .map((v) => {
        const x = v.charCodeAt(0);
        return `${x < 16 ? '0' : ''}${x.toString(16)}`;
      })
      .join('') || ''
  );
};

export default asciiToHex;
