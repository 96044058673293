import React, { createContext, useCallback, useContext, useState } from 'react';
import { NOOP, filterTranslations } from 'helpers';
import { useGLGOParams } from 'hooks';
import { useLocation, useRouteLoaderData } from 'react-router-dom';
import { ProjectResponse, ProjectTranslationResponse, Xapis } from 'store';
import { FormData, SelectedMethod } from './utils';

type Context = {
  showForm: boolean;
  setShowForm: React.Dispatch<React.SetStateAction<boolean>>;
  accordionValue: string | null;
  setAccordionValue: React.Dispatch<React.SetStateAction<string | null>>;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  showChangeMethodModal: boolean;
  setShowChangeMethodModal: React.Dispatch<React.SetStateAction<boolean>>;
  showConfirmMethodModal: boolean;
  setShowConfirmMethodModal: React.Dispatch<React.SetStateAction<boolean>>;
  selectedMethod: SelectedMethod;
  setSelectedMethod: React.Dispatch<React.SetStateAction<SelectedMethod>>;
  projectKey: string;
  activeProject: ProjectKey | undefined;
  setActiveProject: React.Dispatch<
    React.SetStateAction<ProjectKey | undefined>
  >;
  targets: TranslationKey[];
  setTargets: React.Dispatch<React.SetStateAction<TranslationKey[]>>;
  updateDeploymentDetails: (
    data: FormData
  ) => Promise<[ProjectResponse, ...ProjectTranslationResponse[]]>;
};

const defaultContext = {
  showForm: false,
  setShowForm: NOOP,
  accordionValue: '',
  setAccordionValue: NOOP,
  loading: false,
  setLoading: NOOP,
  showChangeMethodModal: false,
  setShowChangeMethodModal: NOOP,
  showConfirmMethodModal: false,
  setShowConfirmMethodModal: NOOP,
  selectedMethod: '',
  setSelectedMethod: NOOP,
  projectKey: '',
  activeProject: undefined,
  setActiveProject: NOOP,
  targets: [],
  setTargets: NOOP,
  updateDeploymentDetails: () => Promise.reject([undefined, []]),
};

const DeploymentContext = createContext<Context>(defaultContext);

function DeploymentProvider({ children }: { children?: React.ReactNode }) {
  const { projectKey = '' } = useGLGOParams();
  const { project, project: { translations = [] } = {} } = useRouteLoaderData(
    'settings'
  ) as ProjectResponse;

  const openEditDomainsForm =
    (useLocation().state?.openEditDomainsForm as boolean) || false;

  const [showForm, setShowForm] = useState(openEditDomainsForm);
  const [accordionValue, setAccordionValue] = useState<string | null>(
    openEditDomainsForm ? 'details' : ''
  );
  const [loading, setLoading] = useState(false);
  const [showChangeMethodModal, setShowChangeMethodModal] = useState(false);
  const [showConfirmMethodModal, setShowConfirmMethodModal] = useState(false);
  const [selectedMethod, setSelectedMethod] = useState<SelectedMethod>('');

  const [activeProject, setActiveProject] = useState(project);
  const [targets, setTargets] = useState(filterTranslations(translations));

  const updateDeploymentDetails = useCallback(
    async (data: FormData) => {
      if (!projectKey) {
        return Promise.reject([]);
      }

      const {
        deploymentValues = [],
        deploymentMethod,
        deploymentName,
        prevDeploymentName,
      } = data || {};

      const isUpdatingProject =
        deploymentName !== undefined &&
        (!prevDeploymentName || deploymentName !== prevDeploymentName);

      const projectData = deploymentMethod
        ? {
            deployment_method: deploymentMethod,
            deployment_name: deploymentName,
          }
        : {
            deployment_name: deploymentName,
          };

      const updateProjectDeployment = isUpdatingProject
        ? Xapis.Project.put(projectKey, projectData)
        : undefined;

      const updateTargetDeploymentValues = targets.map(
        ({ translation_key, deployment_value }, index) => {
          const currentValueString = Array.isArray(deployment_value)
            ? deployment_value.join(',')
            : deployment_value;

          const updatedValue = deploymentValues[index] || [];
          const updatedValueString = Array.isArray(updatedValue)
            ? updatedValue.join(',')
            : updatedValue;

          if (currentValueString !== updatedValueString) {
            return Xapis.ProjectTranslation.put(projectKey, translation_key, {
              deployment_value: updatedValueString,
            });
          }
          return undefined;
        }
      );

      return Promise.all([
        updateProjectDeployment,
        ...updateTargetDeploymentValues,
      ])
        .then((responses) => {
          const [projectResponse, ...targetResponses] = responses || [];

          if (projectResponse?.project) {
            setActiveProject(projectResponse.project);
          }

          if (targetResponses && targetResponses.length > 0) {
            const filteredTargetResponses = targetResponses.filter(
              (response) => {
                if (response && response.data) {
                  return true;
                }
                return false;
              }
            ) as ProjectTranslationResponse[];

            const updatedFilteredTargets = filterTranslations(
              filteredTargetResponses.map(
                ({ data }) => data
              ) as TranslationKey[]
            );

            setTargets((prevTargets) =>
              [...prevTargets].map((prevTarget) => {
                const { target_lang_code = '' } = prevTarget || {};
                const updateTargetFound = updatedFilteredTargets.find(
                  (updatedTarget) => {
                    return updatedTarget.target_lang_code === target_lang_code;
                  }
                );
                if (updateTargetFound) {
                  return updateTargetFound;
                }
                return prevTarget;
              })
            );
          }
          return responses;
        })
        .catch((errors) => {
          return errors;
        });
    },
    [projectKey, targets]
  );

  return (
    <DeploymentContext.Provider
      value={{
        showForm,
        setShowForm,
        accordionValue,
        setAccordionValue,
        loading,
        setLoading,
        showChangeMethodModal,
        setShowChangeMethodModal,
        showConfirmMethodModal,
        setShowConfirmMethodModal,
        selectedMethod,
        setSelectedMethod,
        projectKey,
        activeProject,
        setActiveProject,
        targets,
        setTargets,
        updateDeploymentDetails,
      }}
    >
      {children}
    </DeploymentContext.Provider>
  );
}

function useDeploymentContext() {
  const context = useContext(DeploymentContext);
  if (!context) throw new Error('Not inside the Provider');
  return context;
}
export { useDeploymentContext, DeploymentProvider };
