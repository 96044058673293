const globalRouterConstants = {
  PAGE_ROOT: '/',
};

export const GRC = globalRouterConstants;

// Specific XAPIS HTTP response status code when a resource is already made so no further action is taken (usually for POST)
export const ALREADY_CREATED_CODE = 208;

export const NOOP = () => undefined;
export const LAST_PROJECT_KEY_COOKIE = 'glgoLastProjectKey';

export default GRC;
