import React from 'react';
import { Tooltip } from '@mantine/core';

const FolderMethodTooltip = ({ children }: { children: React.ReactNode }) => (
  <Tooltip
    inline
    multiline
    withArrow
    withinPortal
    w={300}
    events={{ hover: true, touch: true, focus: false }}
    label="The paths specified here must be exact. For instance, if you
                specify “/fr/” as the translated folder path for French and
                an end-user goes to example.com/fr, the user will see the
                source language site instead of the translated one."
    position="bottom-start"
    arrowSize={8}
    arrowOffset={10}
    p="0.75rem"
  >
    {children}
  </Tooltip>
);

export default FolderMethodTooltip;
