import { Box } from '@mantine/core';

const tagPaths = {
  a: 'M2.25 4.25.75 6C0 7 .35 8.25 1 9 1.65 9.75 3 10 4 9.25L5.75 7.75M4.25 2.25 6 .75C7 0 8.25.25 9 1 9.75 1.75 10 3 9.25 4L7.75 5.75M10 0M6.5 3.5 3.5 6.5',
  b: 'M3 0H7.3C8 0 8.4.4 8.8 1 9 1.5 9 1.5 9.1 2.1 9.1 3 9 3 8.9 3.5 8.6 4 8.4 4.3 7.9 4.5H3V0ZM3 4.5H7.9C8.8 4.9 9.19 5.17 9.6 6 9.73 6.4 9.78 6.71 9.8 7.2 9.8 7.8 9.7 8.3 9.6 8.6 9.1 9.6 8.8 9.7 8 10H3Z',
  span: 'M4 0C2 0 2 0 1.67 4 1.53 4.58.6 4.65 0 4.75V5.25C.59 5.35 1.55 5.46 1.67 6 2 10 2 10 4 10M6 0C8 0 8 0 8.33 3.99 8.51 4.56 9.4 4.65 10 4.75V5.25C9.4 5.35 8.46 5.52 8.33 6.02 8 10 8 10 6 10',
  i: 'M10 0H4M6 10H0M7 0 3 10',
  u: 'M1.5 0V5C1.5 6 2 6.8 2.2 7 3 8 4.6 8 5 8 5.4 8 7 8 7.8 7 8 6.8 8.5 6 8.5 5V0M0 10H10',
} as const;

const tagForm = {
  sc: 'M19.75 10.75 16.25 17C16 17.65 15.65 18 15 18L1.35 18C.65 18 0 17.27 0 16.47V3.47C0 2.57.65 2 1.35 2L15 2C15.65 2 16 2.35 16.25 3L19.75 9.25C20 9.75 20 10.18 19.75 10.75Z',
  ec: 'M.25 9.25 3.75 3C4 2.35 4.35 2 5 2L18.65 2C19.35 2 20 2.73 20 3.53V16.53C20 17.43 19.35 18 18.65 18L5 18C4.35 18 4 17.65 3.75 17L.25 10.75C0 10.25 0 9.82.25 9.25Z',
  none: 'm2 9 0-6c0-.7.3-1 1-1l14 0c.7 0 1 .3 1 1v14c0 .6-.2 1-1 1l-14 0c-.7-0-1-.3-1-1z',
} as const;

type TagForm = keyof typeof tagForm;

type TagIconsProps = {
  tag?: string;
  kind?: TagForm;
};

export const TagIcon = ({ kind = 'none', tag = 'span' }: TagIconsProps) => {
  const skewX = kind === 'sc' ? 4 : 6;
  const path = tagPaths[tag as keyof typeof tagPaths] || tagPaths.span;

  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 17"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d={tagForm[kind]} />
      <g transform={`translate(${skewX}, 5)`}>
        <path
          d={path}
          strokeWidth={1.4}
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export const QTag = ({ kind = 'sc' }: TagIconsProps) => {
  return (
    <Box
      component="span"
      c="#2C49AC"
      fz="larger"
      style={{
        transform: `translateY(-1px)`,
        whiteSpace: 'pre-wrap',
      }}
    >
      {kind === 'sc' ? <>&ldquo;</> : <>&rdquo;</>}
    </Box>
  );
};
