import * as React from 'react';
import { useForm } from '@mantine/form';
import {
  Button,
  Flex,
  Group,
  NumberInput,
  Select,
  TextInput,
} from '@mantine/core';
import { BaseSku } from 'store';

const defaultInitialValues: BaseSku = {
  sku: '',
  description: '',
  name: '',
  price: '0.00',
  subscription_type: '',
  threshold_type: '',
  threshold_value: 0,
  sku_type: 'NORMAL',
};

export const SKUForm = ({
  submit,
  initialValues = defaultInitialValues,
  editMode = false,
}: {
  submit: (data: BaseSku) => void;
  initialValues?: BaseSku;
  editMode?: boolean;
}) => {
  const form = useForm({
    initialValues: { ...initialValues, price: parseFloat(initialValues.price) },
    validate: {
      sku: (value: string) => {
        const test1 = /^[A-z0-9\-_]+$/.test(value)
          ? null
          : 'Invalid SKU, allowed characters in an SKU are A-Z, a-z, 0-9, _, - ';
        const test2 =
          value.length > 2
            ? null
            : 'Invalid SKU, SKUs must be at least 3 characters';
        return test1 || test2;
      },
      name: (value: string) =>
        value.length < 10 ? 'Name must have at least 10 letters' : null,
      description: (value: string) =>
        value.length < 10 ? 'Description must have at least 10 letters' : null,
    },
    validateInputOnChange: ['sku', 'name', 'description', 'price'],
    validateInputOnBlur: true,
  });
  const formSubmit = () => {
    const { hasErrors } = form.validate();
    if (!hasErrors) {
      const { values } = form;
      const data = { ...values, price: values.price.toString() };
      submit(data);
    }
  };

  const data = [
    { value: '', label: 'Select an option (not required)' },
    { value: 'MONTHLY', label: 'Monthly' },
    { value: 'YEARLY', label: 'Yearly' },
  ];

  const skuTypes = ['NORMAL', 'ENTERPRISE'] as const;

  return (
    <form>
      <div style={{ maxWidth: 750, margin: 'auto' }}>
        <TextInput
          readOnly={editMode}
          label="SKU"
          placeholder="SKU"
          {...form.getInputProps('sku')}
        />
        <TextInput
          mt="md"
          label="Name"
          placeholder="Name"
          {...form.getInputProps('name')}
        />
        <TextInput
          mt="md"
          label="Description"
          placeholder="Description"
          {...form.getInputProps('description')}
        />
        <NumberInput
          decimalScale={2}
          mt="md"
          label="Price USD"
          placeholder="Price in USD"
          {...form.getInputProps('price')}
        />
        <Flex gap={10}>
          <Select
            label="Subscription Type"
            placeholder="Subscription Type"
            data={data}
            {...form.getInputProps('subscription_type')}
            w="50%"
          />
          <Select
            label="SKU Type"
            placeholder="SKU Type"
            data={skuTypes}
            {...form.getInputProps('sku_type')}
            w="50%"
          />
        </Flex>

        <TextInput
          mt="md"
          label="Threshold Type"
          placeholder="Threshold Type"
          {...form.getInputProps('threshold_type')}
        />
        <TextInput
          mt="md"
          label="Threshold Volume"
          placeholder="Threshold Volume"
          {...form.getInputProps('threshold_value')}
        />

        <Group justify="center" mt="xl">
          <Button variant="outline" onClick={() => formSubmit()}>
            {editMode ? 'Update' : 'Add'}
          </Button>
        </Group>
      </div>
    </form>
  );
};

export default SKUForm;
