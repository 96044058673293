import { asciiToHex } from 'helpers';
import {
  getCustomCSS,
  BOTTOM_POSITION,
  LEFT_POSITION,
  DARK_THEME,
  LIGHT_THEME,
  RIGHT_POSITION,
  TOP_POSITION,
} from '../../gosetup/LanguageSelectorAppearances';

export const bottomLeftDark = asciiToHex(
  getCustomCSS(BOTTOM_POSITION, LEFT_POSITION, DARK_THEME)
);

export const bottomLeftLight = asciiToHex(
  getCustomCSS(BOTTOM_POSITION, LEFT_POSITION, LIGHT_THEME)
);
export const bottomRightDark = asciiToHex(
  getCustomCSS(BOTTOM_POSITION, RIGHT_POSITION, DARK_THEME)
);
export const bottomRightLight = asciiToHex(
  getCustomCSS(BOTTOM_POSITION, RIGHT_POSITION, LIGHT_THEME)
);

export const topLeftDark = asciiToHex(
  getCustomCSS(TOP_POSITION, RIGHT_POSITION, DARK_THEME)
);
export const topLeftLight = asciiToHex(
  getCustomCSS(TOP_POSITION, RIGHT_POSITION, LIGHT_THEME)
);
export const topRightDark = asciiToHex(
  getCustomCSS(TOP_POSITION, LEFT_POSITION, DARK_THEME)
);
export const topRightLight = asciiToHex(
  getCustomCSS(TOP_POSITION, LEFT_POSITION, LIGHT_THEME)
);
