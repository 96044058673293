export const hexToAscii = (hexString: string) => {
  if (!hexString) {
    return '';
  }
  try {
    return decodeURIComponent(hexString.toString().replace(/(..)/g, '%$1'));
  } catch (error) {
    console.error(error);
    return '';
  }
};

export default hexToAscii;
