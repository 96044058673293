export const OidcConfig = {
  client_id: 'OxDyEYoMOYMR6TLudZRAoBQTAnMuNBrCo',
  secret: '1V8VfmLe26tybGLOOdBv7V3NOZl7lGi4H',
  redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/login_redirect`,
  response_type: 'code',
  scope: 'openid email clients OneView xpay',
  authority: 'https://sso-stg.transperfect.com',
  post_logout_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/logout_redirect`,
  automaticSilentRenew: false,
  silent_redirect_uri: `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}/silent_renew`,
  revokeAccessTokenOnSignout: false,
  filterProtocolClaims: true,
  loadUserInfo: true,
  sessionTimeout: 900, // Registered session length 15 minutes
};

export default OidcConfig;
