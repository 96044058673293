import * as React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import 'mantine-datatable/styles.css';
import { ActionIcon, Badge, Flex, Skeleton } from '@mantine/core';
import {
  BaseSku,
  PayKeyStore,
  PaySkuStore,
  SkuWCreatedAndLastMod,
  useBackEndStore,
  usePayKeyStore,
  usePaySkuStore,
  useUserStore,
} from 'store';
import { failure, formatDollarAmount, success } from 'helpers';
import { IconEdit, IconTrash } from '@tabler/icons';
import DeleteModal from './DeleteModal';
import EditSkuDrawer from './editDrawer';
import AddSkuDrawer from './addDrawer';
import {
  MRT_ColumnDef,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import clsx from 'clsx';
import classes from './SkuContent.module.css';
import useSharedTableConfig from '../config-hooks/useSharedTableConfig';

const data: BaseSku = {
  sku: '',
  description: '',
  name: '',
  price: '0',
  subscription_type: '',
  threshold_type: '',
  threshold_value: 0,
  sku_type: 'NORMAL',
};

type Props = {
  pageSize?: number;
};

export const SkuContent = ({ pageSize = 13 }: Props) => {
  const [fetching, setFetching] = useState(true);
  const [page] = useState(1);

  const [, setLoading] = useState(false);
  const [editDrawerOpened, setEditDrawerOpened] = useState(false);
  const [deleteModalOpened, setDeleteModalOpened] = useState(false);
  const [deleteThisSku, setDeleteThisSku] = useState('');
  const [formInitialValues, setFormInitialValues] = useState(data);
  const { skus, fetchSkus, createSku, deleteSku, updateSku } = usePaySkuStore(
    (state: PaySkuStore) => state
  );
  const payKey = usePayKeyStore((state: PayKeyStore) => state.payKey);
  const xapisUrl = useBackEndStore().backend.xapisUrl;
  const user_key = useUserStore().user.user_key;

  useEffect(() => {
    setFetching(true);
    try {
      fetchSkus(payKey, { page, results: pageSize, user_key });
    } catch (error) {
      failure(error);
    } finally {
      setFetching(false);
    }
  }, [payKey, xapisUrl, fetchSkus, page, pageSize, user_key]);

  const submit = useCallback(
    async (skuData: BaseSku) => {
      const data = { ...skuData, user_key };

      try {
        createSku(payKey, data, () => {
          fetchSkus(payKey, { results: pageSize, user_key });
        });
      } catch (err) {
        failure(err);
      } finally {
        setLoading(false);
      }
    },
    [createSku, fetchSkus, pageSize, payKey, user_key]
  );

  const update = async (skuData: BaseSku) => {
    const data = { ...skuData, user_key };

    try {
      updateSku(payKey, data, () => {
        fetchSkus(payKey, { results: pageSize, user_key });
        setEditDrawerOpened(false);
      });
    } catch (err) {
      failure(err);
    } finally {
      setLoading(false);
    }
  };

  const delSku = async (sku: BaseSku) => {
    try {
      if (sku) setDeleteThisSku(sku.sku);
      setDeleteModalOpened(true);
    } catch (error) {
      failure(error, 'missing sku');
    }
  };
  const doDeleteSku = async () => {
    setLoading(true);
    try {
      deleteSku(payKey, deleteThisSku, user_key, () => {
        fetchSkus(payKey, { results: pageSize, user_key });
        success('Successfully removed the sku');
      });
    } catch (error) {
      failure(error, 'missing sku');
    } finally {
      setDeleteModalOpened(false);
      setLoading(false);
    }
  };

  const editSku = useCallback(
    (sku: SkuWCreatedAndLastMod) => {
      try {
        const currentSku = skus.find((p: BaseSku) => p.sku === sku.sku);
        if (currentSku) setFormInitialValues(currentSku);
        setEditDrawerOpened(true);
      } catch (error) {
        failure(error, 'missing sku');
      }
    },
    [skus]
  );

  const columns = useMemo<MRT_ColumnDef<SkuWCreatedAndLastMod>[]>(
    () => [
      {
        accessorFn: (row) => row,
        Cell: ({ cell }) => (
          <Flex gap={5}>
            <ActionIcon
              color="blue"
              onClick={() => editSku(cell.getValue() as SkuWCreatedAndLastMod)}
              variant="transparent"
            >
              <IconEdit size={16} />
            </ActionIcon>
            <ActionIcon
              color="red"
              onClick={() => delSku(cell.getValue() as SkuWCreatedAndLastMod)}
              variant="transparent"
            >
              <IconTrash size={16} />
            </ActionIcon>
          </Flex>
        ),
        enableSorting: false,
        header: '',
        Header: <AddSkuDrawer create={submit} setLoading={setLoading} />,
        id: 'row',
        size: 50,
      },
      {
        id: 'sku',
        Cell: ({ cell }) =>
          cell.row.original.sku_type === 'ENTERPRISE' ? (
            <Flex direction="column" gap={10}>
              <>{cell.row.original.sku}</>
              <Badge color="blue">{cell.row.original.sku_type}</Badge>
            </Flex>
          ) : (
            <>{cell.row.original.sku}</>
          ),
        header: 'SKU',
      },
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'description',
        header: 'Description',
      },
      {
        id: 'price',
        accessorFn: (row) => formatDollarAmount(row.price),
        header: 'Price',
      },
      {
        accessorKey: 'subscription_type',
        header: 'Subscription Type',
      },
      {
        accessorKey: 'threshold_type',
        header: 'Threshold Type',
      },
      {
        accessorKey: 'threshold_value',
        header: 'Threshold',
      },
    ],
    [editSku, submit]
  );

  const table = useMantineReactTable({
    columns,
    data: skus,
    ...useSharedTableConfig<SkuWCreatedAndLastMod>(clsx(classes.table)),
  });

  //using MRT_Table instead of MantineReactTable if we do not want any of the toolbar features
  return (
    <>
      <EditSkuDrawer
        update={update}
        data={formInitialValues}
        opened={editDrawerOpened}
        setOpened={setEditDrawerOpened}
      />
      <DeleteModal
        opened={deleteModalOpened}
        setOpened={setDeleteModalOpened}
        deleteThisSku={deleteThisSku}
        doDeleteSku={doDeleteSku}
      />
      {fetching ? (
        <Skeleton h="100vh" w="100%" />
      ) : (
        <MantineReactTable table={table} />
      )}
    </>
  );
};

export default SkuContent;
