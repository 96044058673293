import { create } from 'zustand';
import Get from './xapis-wrappers/Fetcher';
import moment from 'moment/moment';
import { FetcherPromise } from './xapis-wrappers';

export type WordsServedResponse = {
  count: number;
  timeslice: string;
  translation_key: string;
};

export type WordsServed = {
  count: number;
  timeslice: string;
  translationKey: string;
};

export type WordsServedStore = {
  wordsServed: WordsServed[];
  monthlyWordsServed: WordsServed[];
  prevWordsServed: WordsServed[];
  loading: boolean;
  fetchWordsServed: (
    translationKey: string,
    timesliceFrom: string,
    rollup: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
  fetchMonthlyWordsServed: (
    translationKey: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
  fetchPrevWordsServed: (
    translationKey: string,
    timesliceFrom: string,
    timesliceTo: string,
    rollup: string,
    failed: { errorMessage?: string; action?: () => void }
  ) => void;
  resetWordsServed: () => void;
};

const baseParams = {
  v: 2,
  type: 'groupby',
  groupby: 'timeslice,translation_key',
  series: 'tot_words_served',
  rollup: 'day',
};
const baseParamsWithoutRollup = {
  v: 2,
  type: 'groupby',
  groupby: 'timeslice,translation_key',
  series: 'tot_words_served',
};

const parseWordsServed = (
  wordsServed: WordsServedResponse[]
): WordsServed[] => {
  return wordsServed.map((w) => {
    return {
      count: w.count,
      timeslice: w.timeslice,
      translationKey: w.translation_key,
    };
  });
};

export const useWordsServedStore = create<WordsServedStore>()((set, get) => ({
  wordsServed: [],
  monthlyWordsServed: [],
  prevWordsServed: [],
  loading: false,
  fetchWordsServed: (translationKey, timesliceFrom, rollup, failed) => {
    const { loading } = get();
    if (loading) return;

    set({ loading: true });
    Get(
      'Metrics',
      ({ data: { data = [] } }: { data: { data: WordsServedResponse[] } }) => {
        const parsedWordsServed = parseWordsServed(data);
        set({ wordsServed: parsedWordsServed });
      },
      {
        errorMessage: failed.errorMessage || '',
        action: () => {
          set({ wordsServed: [] });
          failed.action && failed.action();
        },
      },
      {
        translation_key: translationKey,
        timeslice_from: timesliceFrom,
        rollup,
        ...baseParamsWithoutRollup,
      },
      () => set({ loading: false })
    );
  },
  fetchMonthlyWordsServed: (translationKey, failed) => {
    const date = new Date();
    const timesliceFrom = moment(date).format('YYYY-MM-01');
    const yesterday = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
    const timesliceTo = yesterday < timesliceFrom ? timesliceFrom : yesterday;

    Get(
      'Metrics',
      ({ data: { data = [] } }: { data: { data: WordsServedResponse[] } }) => {
        const parsedMonthlyWordsServed = parseWordsServed(data);
        set({ monthlyWordsServed: parsedMonthlyWordsServed });
      },
      {
        errorMessage: failed.errorMessage || '',
        action: () => {
          set({ monthlyWordsServed: [] });
          failed.action && failed.action();
        },
      },
      {
        translation_key: translationKey,
        timeslice_from: timesliceFrom,
        timeslice_to: timesliceTo,
        ...baseParams,
      }
    );
  },
  fetchPrevWordsServed: (
    translationKey,
    timesliceFrom,
    timesliceTo,
    rollup,
    failed
  ) => {
    const { loading } = get();
    if (loading) {
      return;
    }

    set({ loading: true });
    Get(
      'Metrics',
      ({ data: { data = [] } }: { data: { data: WordsServedResponse[] } }) => {
        const parsedPrevWordsServed = parseWordsServed(data);
        set({ prevWordsServed: parsedPrevWordsServed });
      },
      {
        errorMessage: failed.errorMessage || '',
        action: () => {
          set({ prevWordsServed: [] });
          failed.action && failed.action();
        },
      },
      {
        translation_key: translationKey,
        timeslice_from: timesliceFrom,
        timeslice_to: timesliceTo,
        rollup,
        ...baseParamsWithoutRollup,
      },
      () => set({ loading: false })
    );
  },
  resetWordsServed: () => {
    set({
      wordsServed: [],
      monthlyWordsServed: [],
      prevWordsServed: [],
    });
  },
}));

export default useWordsServedStore;

export const fetchWordsServedPromise = (
  translationKey: string,
  timesliceFrom: string,
  rollup: string
) => {
  return FetcherPromise('Metrics', {
    translation_key: translationKey,
    timeslice_from: timesliceFrom,
    rollup,
    ...baseParamsWithoutRollup,
  });
};

export const fetchMonthlyWordsServedPromise = (translationKey: string) => {
  const date = new Date();
  const timesliceFrom = moment(date).format('YYYY-MM-01');
  const today = moment(date).subtract(1, 'days').format('YYYY-MM-DD');
  const timesliceTo = today < timesliceFrom ? timesliceFrom : today;

  return FetcherPromise('Metrics', {
    translation_key: translationKey,
    timeslice_from: timesliceFrom,
    timeslice_to: timesliceTo,
    ...baseParams,
  });
};

export const fetchPrevWordsServedPromise = (
  translationKey: string,
  timesliceFrom: string,
  timesliceTo: string,
  rollup: string
) => {
  const baseParamsWithoutRollup = {
    v: 2,
    type: 'groupby',
    groupby: 'timeslice,translation_key',
    series: 'tot_words_served',
  };

  return FetcherPromise('Metrics', {
    translation_key: translationKey,
    timeslice_from: timesliceFrom,
    timeslice_to: timesliceTo,
    rollup,
    ...baseParamsWithoutRollup,
  });
};
