import React from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  ScrollArea,
  Text,
  TextInput,
  Tooltip,
} from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import { QUERY, hasValidDeploymentValues } from '../utils';
import FooterButtons from '../FooterButtons';
import { useDeploymentContext } from '../DeploymentContextProvider';

const EditQueryMethod = () => {
  const {
    loading,
    setLoading,
    setShowForm,
    activeProject: {
      deployment_name: currentDeploymentName = '',
      origin_name: domain = '',
    } = {},
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();
  const parsedDomain = domain.replaceAll('www.', '');

  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { isDirty = false },
  } = useForm({
    defaultValues: {
      deploymentName: currentDeploymentName,
      targetValues: targets,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const watchedDeploymentName = watch('deploymentName');

  const handleUpdate = async (data: {
    deploymentName: string;
    targetValues: TranslationKey[];
  }) => {
    const { deploymentName = '', targetValues = [] } = data || {};

    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    if (!isDirty) {
      setShowForm(false);
      return;
    } else if (
      !deploymentName ||
      !hasValidDeploymentValues(deploymentValues, QUERY)
    ) {
      notifications.show({ message: 'A deployment field is missing a value' });
      return;
    } else {
      setLoading(true);

      updateDeploymentDetails({
        deploymentName,
        deploymentValues,
        prevDeploymentName: currentDeploymentName,
      })
        .then(() => {
          notifications.show({
            message: 'Successfully updated deployment details!',
          });
        })
        .finally(() => {
          setShowForm(false);
          setLoading(false);
        });
    }
  };

  return (
    <Flex direction="column" rowGap="2rem" opacity={loading ? 0.5 : 1}>
      <Card
        h={260}
        p={0}
        style={{ boxShadow: 'none' }}
        opacity={loading ? 0.5 : 1}
      >
        <Flex pl="1.5rem">
          <Box w="30%" pt="1.65rem" pr="0.5rem">
            <Text fw={600}>Query Parameter</Text>
          </Box>
          <Flex w="70%" h="5rem" pr="1.5rem" pl="0.5rem" align="center">
            <TextInput
              w="100%"
              maw="15rem"
              size="md"
              defaultValue={currentDeploymentName}
              {...register('deploymentName')}
            />
          </Flex>
        </Flex>
        <Divider size="xs" orientation="horizontal" color="divider.1" />
        <Flex h="10rem" pl="1.5rem" py="0.5rem">
          <Box w="30%" pt="1rem" pr="0.5rem">
            <Text fw={600}>Query Value</Text>
          </Box>
          <Box w="70%" pl="0.5rem">
            <ScrollArea
              h="100%"
              pr="1rem"
              scrollbarSize={22}
              type="always"
              offsetScrollbars
              className="scrollArea"
            >
              {targetValues.map(
                (
                  { id = '', target_lang_name = '', deployment_value = '' },
                  index
                ) => {
                  const isLastIndex = index === targetValues.length - 1;
                  const watchedDeploymentValue = watch(
                    `targetValues.${index}.deployment_value`
                  );

                  return (
                    <Box key={id}>
                      <Flex h="5rem">
                        <Flex w="70%" align="center" pr="1rem">
                          <Tooltip
                            withinPortal
                            position="bottom-start"
                            events={{ hover: true, touch: true, focus: false }}
                            label={
                              <Text
                                c="text.2"
                                fz="0.9rem"
                                fw={400}
                                truncate
                                span
                              >
                                {`${parsedDomain}/?${watchedDeploymentName}=`}
                                <Text c="text.2" fw={700} truncate span>
                                  {watchedDeploymentValue}
                                </Text>
                              </Text>
                            }
                          >
                            <Text truncate>
                              {target_lang_name}
                              <br />
                              <Text
                                c="text.3"
                                fz="0.9rem"
                                fw={400}
                                truncate
                                span
                              >
                                {`${parsedDomain}/?${watchedDeploymentName}=`}
                                <Text c="text.3" fw={700} truncate span>
                                  {watchedDeploymentValue}
                                </Text>
                              </Text>
                            </Text>
                          </Tooltip>
                        </Flex>
                        <Flex w="30%" align="center" pl="1rem">
                          <TextInput
                            maw="15rem"
                            size="md"
                            {...register(
                              `targetValues.${index}.deployment_value`
                            )}
                            defaultValue={deployment_value}
                          />
                        </Flex>
                      </Flex>
                      {!isLastIndex && (
                        <Divider
                          size="xs"
                          orientation="horizontal"
                          color="divider.1"
                        />
                      )}
                    </Box>
                  );
                }
              )}
            </ScrollArea>
          </Box>
        </Flex>
      </Card>
      <Divider size="xs" orientation="horizontal" color="divider.1" />
      <FooterButtons
        loading={loading}
        onCancel={() => {
          setShowForm(false);
        }}
        onSave={handleSubmit(handleUpdate)}
      />
    </Flex>
  );
};

export default EditQueryMethod;
