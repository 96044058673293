import React, { useEffect } from 'react';
import {
  Box,
  Card,
  Divider,
  Flex,
  ScrollArea,
  Text,
  TextInput,
  useMantineTheme,
} from '@mantine/core';
import { useFieldArray, useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';
import {
  DOMAIN,
  addDeploymentValue,
  removeDeploymentValue,
  getDeploymentTargets,
  TargetFormValue,
  FormValues,
  hasValidDeploymentValues,
} from '../utils';
import { MdAdd, MdOutlineRemoveCircleOutline } from 'react-icons/md';
import FooterButtons from '../FooterButtons';
import { useDeploymentContext } from '../DeploymentContextProvider';
import EmptyValueWarning from './EmptyValueWarning';

const EditDomainMethod = () => {
  const { colors } = useMantineTheme();
  const {
    loading,
    setLoading,
    setShowForm,
    targets = [],
    updateDeploymentDetails,
  } = useDeploymentContext();
  const defaultTargetValues = getDeploymentTargets(targets);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    setValue,
    trigger,
    formState,
  } = useForm<FormValues>({
    defaultValues: {
      targetValues: defaultTargetValues,
    },
  });
  const { fields: targetValues } = useFieldArray({
    control,
    name: 'targetValues',
  });

  const { isDirty = false, errors } = formState;

  if (window.history.state) window.history.replaceState({}, '');

  const handleUpdate = async (data: { targetValues: TargetFormValue[] }) => {
    const { targetValues = [] } = data || {};
    const deploymentValues = targetValues.map(
      ({ deployment_value }) => deployment_value
    );

    if (!hasValidDeploymentValues(deploymentValues, DOMAIN)) {
      notifications.show({
        color: 'red',
        message: 'A valid Domain pattern is required per language',
      });
      return;
    } else {
      setLoading(true);

      updateDeploymentDetails({ deploymentValues })
        .then(() => {
          notifications.show({
            message: 'Successfully updated deployment details!',
          });
        })
        .finally(() => {
          setShowForm(false);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const emptyValueIndex = targetValues.findIndex(
      (t) => t.deployment_value[0] === ''
    );
    if (emptyValueIndex !== -1) {
      trigger(`targetValues.${emptyValueIndex}.deployment_value.0`, {
        shouldFocus: true,
      });
    }
    // need this to only fire on mount
    // adding pointless object deps to the deps array obscures that

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isDirty) return;
    function handleBeforeUnload(e: BeforeUnloadEvent) {
      e.preventDefault();
      return (e.returnValue = '');
    }

    window.addEventListener('beforeunload', handleBeforeUnload, {
      capture: true,
    });
    return () =>
      window.removeEventListener('beforeunload', handleBeforeUnload, {
        capture: true,
      });
  }, [isDirty]);

  return (
    <Box opacity={loading ? 0.5 : 1}>
      <form onSubmit={handleSubmit(handleUpdate)}>
        <Flex direction="column" gap={20}>
          <Card
            h={225}
            p={0}
            style={{ boxShadow: 'none' }}
            opacity={loading ? 0.5 : 1}
          >
            <Flex h="100%" pl="1.5rem" py="0.5rem">
              <Box w="20%" pt="1.5rem" pr="0.5rem">
                <Text fw={600}>Language</Text>
              </Box>
              <Box w="80%" pl="0.5rem">
                <ScrollArea
                  h="100%"
                  pr="1rem"
                  scrollbarSize={22}
                  type="always"
                  offsetScrollbars
                  className="scrollArea"
                >
                  {targetValues.map(
                    (
                      {
                        id = '',
                        target_lang_code = '',
                        target_lang_name = '',
                        deployment_value = [],
                      },
                      targetIndex
                    ) => {
                      const isLastIndex =
                        targetIndex === targetValues.length - 1;
                      const hasMultipleValues = deployment_value.length > 1;

                      return (
                        <Box key={id}>
                          <Flex py={hasMultipleValues ? '1.15rem' : 0}>
                            <Flex
                              w="35%"
                              h={hasMultipleValues ? '2.65rem' : '5rem'}
                              align="center"
                              pr="1rem"
                            >
                              <Text truncate>{target_lang_name}</Text>
                            </Flex>
                            <Flex
                              w="50%"
                              direction="column"
                              rowGap="0.5rem"
                              justify={
                                hasMultipleValues ? 'flex-start' : 'center'
                              }
                              px="1rem"
                            >
                              {deployment_value.map((value, valueIndex) => {
                                const hasRequiredError =
                                  errors?.targetValues?.[targetIndex]
                                    ?.deployment_value?.[valueIndex]?.type ===
                                  'required';

                                return (
                                  <Flex
                                    align="center"
                                    key={`${id}-${valueIndex}`}
                                    pos={
                                      hasRequiredError ? 'relative' : 'initial'
                                    }
                                  >
                                    {hasRequiredError && (
                                      <EmptyValueWarning
                                        hasMultipleValues={hasMultipleValues}
                                        color={colors.text[4]}
                                      />
                                    )}
                                    <Flex
                                      h={hasMultipleValues ? '2.65rem' : '5rem'}
                                      align="center"
                                    >
                                      <TextInput
                                        w="100%"
                                        maw="15rem"
                                        size="md"
                                        {...register(
                                          `targetValues.${targetIndex}.deployment_value.${valueIndex}`,
                                          {
                                            required: true,
                                          }
                                        )}
                                        styles={{
                                          input: {
                                            borderColor: hasRequiredError
                                              ? `${colors.text[4]}`
                                              : 'auto',
                                          },
                                        }}
                                        defaultValue={value}
                                      />
                                    </Flex>
                                  </Flex>
                                );
                              })}
                            </Flex>
                            <Flex
                              w="15%"
                              mih="5rem"
                              align={hasMultipleValues ? 'flex-end' : 'center'}
                            >
                              <Flex
                                h="2.5rem"
                                align="center"
                                columnGap="0.5rem"
                              >
                                {hasMultipleValues && (
                                  <Box
                                    h={25}
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                      removeDeploymentValue(
                                        target_lang_code,
                                        getValues,
                                        setValue
                                      )
                                    }
                                  >
                                    <MdOutlineRemoveCircleOutline
                                      size={25}
                                      color={colors.icon[0]}
                                    />
                                  </Box>
                                )}
                                <Box
                                  h={25}
                                  style={{ cursor: 'pointer' }}
                                  onClick={() =>
                                    addDeploymentValue(
                                      target_lang_code,
                                      getValues,
                                      setValue
                                    )
                                  }
                                >
                                  <MdAdd size={25} color={colors.icon[0]} />
                                </Box>
                              </Flex>
                            </Flex>
                          </Flex>
                          {!isLastIndex && (
                            <Divider
                              size="xs"
                              orientation="horizontal"
                              color="divider.1"
                            />
                          )}
                        </Box>
                      );
                    }
                  )}
                </ScrollArea>
              </Box>
            </Flex>
          </Card>
          <Divider size="xs" orientation="horizontal" color="divider.1" />
          <FooterButtons
            defaultFormSubmit={true}
            disabled={!isDirty}
            loading={loading}
            onCancel={() => {
              setShowForm(false);
            }}
          />
        </Flex>
      </form>
    </Box>
  );
};

export default EditDomainMethod;
