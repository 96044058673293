import { notifications } from '@mantine/notifications';

/* eslint-disable  @typescript-eslint/no-explicit-any */
const getErrorMessage = (error: any): string => {
  if (typeof error === 'string') {
    return error;
  }
  if (typeof error?.message === 'string') {
    return error.message;
  }
  return '';
};

/**
 * Shows an error to the user through a notification UI element that appears at the bottom-right of the screen,
 * as well as printing an error in the console for developers. The idea is to show users a simple error message,
 * but still allow developers to properly debug errors with more thorough error messages.
 *
 * @param error - Usually expects either a string, or an object with a message field ({ message: "example" }), but can take any
 * @param notificationMessage - Optional, if included, will overwrite any *shown* error message to the user
 */

/* eslint-disable  @typescript-eslint/no-explicit-any */
export const failure = (error: any, notificationMessage = '') => {
  const errorMessage: string = getErrorMessage(error);

  console.error('NEXT_ERROR:', error, notificationMessage);
  notifications.show({
    message: notificationMessage || errorMessage || 'Error!',
  });
};

export default failure;
