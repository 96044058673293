import {
  forwardRef,
  useEffect,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Box, Flex, MantineProvider, Text } from '@mantine/core';
import { StoreApi } from 'zustand';
import { Virtuoso } from 'react-virtuoso';
import { HostLabel } from 'helpers';

import { SegmentEditorContext } from './context/SegmentEditorProvider';
import {
  fetchBlockSegmentData,
  fetchSegmentData,
  saveSegmentsTranslation,
  deleteSegmentTranslation,
} from './http/dataAPIs';
import { createHttpService, setAccessToken } from './http/http';
import { Xapis } from './http/xapis';
// Stores
import {
  useSegmentsAttributes,
  useSegmentsStateActions,
  useSelected,
} from './store/SegmentsState';
import { useDashboardActions, useFilters } from './store/DashboardStore';
import {
  EditHistoryStore,
  createEditHistoryStore,
} from './store/EditHistoryStore';
// Types
import { SortBy, SearchBy } from './types/editor';
// Classes
import { SList } from './classes/segmentList';
import { BlockSegmentsMap } from './classes/BlockSegmentsMap';
// Components
import EditorDashboard from './components/Dashboard/EditorDashboard';
import Segment from './components/Segment/Segment';
import SortSegments from './components/Dashboard/SortSegments';
import SearchSegments from './components/Dashboard/SearchSegments';
import SegmentsHeader from './components/Dashboard/SegmentsHeader';
import { Wait } from './components/Generic/Wait';
import { UnsavedChangesModal } from './components/UnsavedChangesModal/UnsavedChangesModal';
import { AlertIcon } from './icons/IndicatorIcons';
// Functions
import {
  blocksToSegments,
  filterSegments,
  generateSegmentsObject,
  getFiltersOptions,
  processSegmentResponse,
  sortSegments,
} from './functions/segmentsFunctions';
// Styling
import { theme } from './theme/theme';
import './global.css';
import '@mantine/dates/styles.css';
import classes from './SegmentEditor.module.css';

export interface UpdateUnsavedList {
  (
    sHash: string,
    action: {
      type: 'SET_TO_LIVE' | 'SET_JLIFF' | 'REMOVE_JLIFF' | 'REMOVE_FROM_LIST';
      payload?: { target?: Jliff[] | null; setToLive?: boolean };
    }
  ): void;
}
export interface SegmentEditorProps {
  filters?: {
    segments?: string[];
    blocks?: string[];
  };
  contextData: ContextData | null;
}

export interface SegmentEditorRef {
  checkForUnsavedChanges: () => Promise<boolean>;
}

function SegmentEditorInternal(
  { contextData, filters }: SegmentEditorProps,
  ref: React.Ref<SegmentEditorRef>
) {
  const { setNotification, setFiltersOptions, setExternalFilter } =
    useDashboardActions();
  const {
    resetUnsaved,
    addModified,
    removeModified,
    addSetToLive,
    removeSetToLive,
    setSaved,
    setFailedSave,
    resetSegmentsState,
    setAttributes,
  } = useSegmentsStateActions();

  const {
    contextAPI = {},
    target,
    settings,
  } = useContext(SegmentEditorContext);

  const { contextMode, accessToken, xapisHost, viewOnly } = settings;
  const isInScope =
    !contextMode || !contextData?.site ? true : contextData.site.isInScope;
  setAccessToken(accessToken);
  createHttpService(xapisHost);
  const { onTranslationChange, onSelect } = contextAPI;
  const onSelectSegment = (sHash: string) => {
    if (typeof onSelect === 'function') {
      onSelect({
        segmentHash: sHash,
        blocks: blockSegmentMap.current.getSegmentsBlocks([sHash]),
      });
    }
  };

  const currentUrl = useRef<string>('');
  const unsavedSegments = useRef(new SList());
  const jliffStores = useRef<Record<string, StoreApi<EditHistoryStore>>>({});
  const [loadingSegments, setLoadingSegments] = useState(false);
  const [saving, setSaving] = useState(false);

  const segmentsAttributes = useSegmentsAttributes();
  const [segmentsObj, setSegments] = useState<SegmentsObj>({});
  const blockSegmentMap = useRef(new BlockSegmentsMap());
  const [sortBy, setSortBy] = useState<SortBy>({
    field: '',
    ascending: true,
  });
  const externalFilter = blockSegmentMap.current.getSegmentsHashes(filters);
  const filterBy = useFilters();
  const [searchBy, setSearchBy] = useState<SearchBy>({ text: '' });
  const allSegmentsHashes = Object.keys(segmentsObj);

  const listRef = useRef(null);
  const [listHeight, setListHeight] = useState(window.innerHeight - 180);

  const [showUnsavedModal, setShowUnsavedModal] = useState(false);
  const [modalCloseResolver, setModalCloseResolver] = useState<
    (() => void) | null
  >(null);

  function updateSegmentsObject(
    state: SegmentsObj,
    action: {
      type: 'ADD' | 'RESET' | 'UPDATE' | 'DELETE';
      payload: { segments: SegmentsObj; attributes?: BlockAttributes | null };
    }
  ) {
    const {
      type,
      payload: { segments, attributes },
    } = action;
    let newSegmentsObj = { ...state };

    switch (type) {
      case 'RESET':
        newSegmentsObj = { ...segments };
        Object.keys(newSegmentsObj).forEach((sHash) => {
          const segment = { ...segments[sHash] };
          // And reset the store for the segment
          const targetJliff =
            segment.target_jliff || segment.segment_jliff || [];
          jliffStores.current[sHash] = createEditHistoryStore(targetJliff);
        });
        resetSegmentsState(attributes);
        break;
      case 'ADD':
        Object.keys(segments).forEach((sHash) => {
          if (!newSegmentsObj[sHash]) {
            const segment = segments[sHash];
            newSegmentsObj[sHash] = segment;
            const targetJliff =
              segment.target_jliff || segment.segment_jliff || [];
            jliffStores.current[sHash] = createEditHistoryStore(targetJliff);
          }
        });
        setAttributes(attributes);
        break;
      case 'UPDATE':
        Object.keys(segments).forEach((sHash) => {
          if (!newSegmentsObj[sHash]) {
            console.warn('updateSegmentsObject: segment not found:', sHash);
          }
          // Override segments object with new segments
          const segment = { ...segments[sHash] };
          newSegmentsObj[sHash] = segment;
          // And reset the store for the segment
          const targetJliff =
            segment.target_jliff || segment.segment_jliff || [];
          jliffStores.current[sHash] = createEditHistoryStore(targetJliff);
        });
        break;
      case 'DELETE':
        if (!segments) return;
        Object.keys(segments).forEach((sHash) => {
          delete newSegmentsObj[sHash];
        });
        break;
      default:
        console.warn('updateSegmentsObject: unknown action:', action);
        return;
    }
    // Update filters options
    if (Object.keys(newSegmentsObj).length)
      setFiltersOptions(getFiltersOptions(newSegmentsObj, attributes));

    setSegments({ ...newSegmentsObj });
  }
  // -----------------------------------------------------
  // Callback function run when context data is received
  async function handleContextData(data: ContextData | null) {
    // console.log('\nSE::gotContextData:', data);
    if (!contextMode || !data) return;

    const { blocksData, site, attributes } = data;

    const newBlocks = blocksData
      ? blocksData.filter(
          (block) => !blockSegmentMap.current.hasBlock(block.block_hash)
        )
      : null;

    if (blocksData && !newBlocks?.length) {
      setLoadingSegments(false);
      return;
    }

    !allSegmentsHashes.length && setLoadingSegments(true);
    const response = await fetchBlockSegmentData(site.urlHash, newBlocks);

    // If status = 200, convert blocks to segments and add to state
    if (response.status === 200 && response.blockSegments) {
      const { blockSegments } = response;
      const newSegments = blocksToSegments(blockSegments);
      if (site.url === currentUrl.current) {
        // Update blocksSegmentsMap
        blockSegmentMap.current.addBlocks({ blockSegments, blocksData });
        // Add new segments to existing segments
        updateSegmentsObject(segmentsObj, {
          type: 'ADD',
          payload: { segments: newSegments, attributes },
        });
      } else {
        // Reset URL
        currentUrl.current = site.url;
        // Create blocksSegmentsMap
        blockSegmentMap.current.setBlocks({ blockSegments, blocksData });
        // Reset segments object with new segments
        updateSegmentsObject(segmentsObj, {
          type: 'RESET',
          payload: { segments: newSegments, attributes },
        });
      }
    } else {
      console.error(
        'SE::Error getting blocks:',
        response.status,
        response.error
      );
    }
    setLoadingSegments(false);
  }

  useEffect(() => {
    handleContextData(contextData);
  }, [contextData]);

  useEffect(() => {
    setExternalFilter(externalFilter);
  }, [externalFilter, setExternalFilter]);

  // resize the list when the window resizes
  useEffect(() => {
    if (!contextMode) {
      setLoadingSegments(true);
      // fetch data from /Segment
      fetchSegmentData()
        .then((res) => {
          const { data, status } = res;
          if (status === 200) {
            const segments = processSegmentResponse(data.segments);
            updateSegmentsObject({}, { type: 'RESET', payload: { segments } });
          } else {
            console.error('Error fetching segments:', res);
          }
        })
        .catch((error) => {
          console.error('Error fetching segments:', error);
        })
        .finally(() => {
          setLoadingSegments(false);
        });
    }

    function updateSize() {
      setListHeight(window.innerHeight - 180);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  // ---- End of useEffects ----

  function previewAllSegments(segments: Segment[]) {
    if (!onTranslationChange) return undefined;
    // Get all segments with modified target
    const changedSegments = unsavedSegments.current.segments.filter(
      (s) => s.target
    );
    // Add segments if it's not in the list
    if (segments?.length) {
      segments.forEach((segment) => {
        if (
          !changedSegments.find((s) => s.segment_hash === segment.segment_hash)
        ) {
          changedSegments.push({
            segment_hash: segment.segment_hash,
            target: segment.target_jliff,
            setToLive: false,
          });
        }
      });
    }

    if (!changedSegments.length) {
      return;
    }

    const segmentsToPreview: PreviewSegment[] = changedSegments.map((s) => ({
      segment_hash: s.segment_hash,
      target: s.target,
      is_staging: segmentsObj[s.segment_hash]?.is_staging,
    }));
    const blocks = blockSegmentMap.current.getSegmentsBlocks(
      segments.map((s) => s.segment_hash)
    );
    onTranslationChange({ segments: segmentsToPreview, blocks });
  }

  // Manage unsaved segments list
  function updateUnsavedList(
    sHash: string,
    action: {
      type: 'SET_TO_LIVE' | 'SET_JLIFF' | 'REMOVE_JLIFF' | 'REMOVE_FROM_LIST';
      payload?: { target?: Jliff[] | null; setToLive?: boolean };
    }
  ) {
    if (!sHash) {
      console.warn('updateUnsavedList: segment hash is missing');
      return;
    }
    const { type, payload } = action;
    const segment = segmentsObj[sHash];
    if (!segment) {
      console.warn('updateUnsavedList: segment is null');
      return;
    }
    const list = unsavedSegments.current;

    switch (type) {
      case 'SET_TO_LIVE':
        if (!payload || payload.setToLive === undefined) {
          console.warn(
            'updateUnsavedList.SET_TO_LIVE: payload is not a boolean'
          );
          return;
        }
        list.setToLive(sHash, payload.setToLive);
        if (payload.setToLive) addSetToLive(sHash);
        else removeSetToLive(sHash);
        break;
      case 'SET_JLIFF':
        if (!payload || !payload.target) {
          console.warn('updateUnsavedList.SET_JLIFF: payload.target is null');
          return;
        }
        list.setTarget(sHash, payload.target);
        addModified(sHash); // Add to modified list
        break;
      case 'REMOVE_JLIFF':
        if (!list.has(sHash)) return; // If not in list, do nothing
        if (list.get(sHash)?.setToLive) {
          // If setToLive = true, set target to null
          list.setTarget(sHash, null);
        } else {
          // If setToLive = false, remove from list
          list.remove(sHash);
        }
        removeModified(sHash);
        break;
      case 'REMOVE_FROM_LIST':
        if (!list.has(sHash)) return; // If not in list, do nothing
        list.remove(sHash);
        break;
      default:
        console.warn('updateUnsavedList: unknown action:', action);
        return;
    }
  }

  useImperativeHandle(ref, () => ({
    checkForUnsavedChanges: async () => {
      if (unsavedSegments.current.length > 0) {
        setShowUnsavedModal(true);

        // Wait for user to resolve modal (save/discard)
        await new Promise((resolve) => {
          setModalCloseResolver(() => resolve);
        });
      }
      return unsavedSegments.current.length === 0;
    },
  }));

  function resetUnsavedList() {
    unsavedSegments.current.reset();
    resetUnsaved();
  }

  const closeModal = () => {
    setShowUnsavedModal(false);
    if (modalCloseResolver) {
      modalCloseResolver();
      setModalCloseResolver(null);
    }
  };

  const handleSave = async () => {
    // Save your changes here...
    await saveAllSegments();
    closeModal();
  };

  const handleDiscard = () => {
    resetUnsavedList();
    closeModal();
  };

  async function deleteSegment(segmentHash: string) {
    const res = await deleteSegmentTranslation(segmentsObj[segmentHash]);
    console.log('Delete segment response:', res);
    if (res.status !== 201) {
      setNotification(`Error deleting segment ${res.data}`, 'error');
      return;
    }
    const deletedSegments = res.data as Segment[];
    const segments = generateSegmentsObject(res.data);
    setNotification('Segment deleted successfully.', 'success');
    console.log('SE::deleteSegment: segments:', deletedSegments);
    // Remove from unsaved list
    deletedSegments.forEach((s) => {
      updateUnsavedList(s.segment_hash, { type: 'REMOVE_FROM_LIST' });
    });
    // Update segments object
    if (contextMode) {
      updateSegmentsObject(segmentsObj, {
        type: 'UPDATE',
        payload: { segments },
      });
      previewAllSegments(deletedSegments);
    } else {
      updateSegmentsObject(segmentsObj, {
        type: 'DELETE',
        payload: { segments },
      });
    }
  }

  async function saveAllSegments(): Promise<void> {
    if (viewOnly) {
      console.error('View Only mode: Save not allowed.');
      return;
    }
    setSaving(true);
    const listToSave = unsavedSegments.current.segments;

    const { data, status } = await saveSegmentsTranslation(
      listToSave,
      segmentsObj
    );
    console.log('Save segments response:', data, status);

    if (status === 200) {
      const saveResponse = data as SegmentContentResponse;
      let saveMessage = '';

      if (saveResponse.segments.length) {
        const newSegments: SegmentsObj = saveResponse.segments.reduce(
          (acc, s) => ({ ...acc, [s.segment_hash]: s }),
          {}
        );
        updateSegmentsObject(segmentsObj, {
          type: 'UPDATE',
          payload: { segments: newSegments },
        });
        const hashes = saveResponse.segments.map((s) => {
          updateUnsavedList(s.segment_hash, { type: 'REMOVE_FROM_LIST' });
          return s.segment_hash;
        });
        setSaved(hashes);
      }

      if (saveResponse.failed?.length) {
        setNotification(
          `Error: ${saveResponse.failed.length} segments not saved!`,
          'error'
        );
        setFailedSave(saveResponse.failed);
      } else {
        const savedSegmentsCount = saveResponse.segments.length;
        saveMessage = `${savedSegmentsCount} segment${
          savedSegmentsCount === 1 ? '' : 's'
        } saved`;
      }

      if (saveMessage) {
        setNotification(saveMessage, 'success');
      }
    } else {
      console.error(`Error (${status}) saving segments:`, data);
    }

    setSaving(false);
  }

  const segmentsHashes =
    externalFilter.length > 0 ? externalFilter : allSegmentsHashes;

  const filteredSegments = filterSegments(
    segmentsObj,
    segmentsHashes,
    filterBy,
    searchBy,
    segmentsAttributes
  );
  const undefinedStores = filteredSegments.filter(
    (sHash) => !jliffStores.current[sHash]
  );
  if (undefinedStores.length)
    console.warn(
      'SE::undefinedStores:',
      undefinedStores.length,
      undefinedStores
    );

  const sortedSegments = sortSegments(
    segmentsObj,
    filteredSegments,
    segmentsAttributes,
    sortBy
  ).filter((sHash) => jliffStores.current[sHash]); // Filter out segments without a store

  function segmentForHash(sHash: string) {
    const segment = segmentsObj[sHash];
    if (unsavedSegments.current.has(sHash)) {
      segment.target_jliff = unsavedSegments.current.getTarget(sHash);
    }

    if (!segment) {
      console.warn('Segment is undefined:', sHash);
    }
    return segment;
  }

  const segmentForIndex = (index: number) => {
    const sHash = sortedSegments[index];
    return segmentForHash(sHash);
  };

  const SegmentRow = ({ index }: { index: number }) => {
    const segment = segmentForIndex(index);

    return segment && jliffStores.current[segment.segment_hash] ? (
      <Segment
        key={segment.segment_hash}
        jliffStore={jliffStores.current[segment.segment_hash]}
        segment={segment}
        onSelect={onSelectSegment}
        filterText={searchBy.text}
        previewAllSegments={previewAllSegments}
        deleteSegment={deleteSegment}
        updateUnsavedList={updateUnsavedList}
        index={index}
      />
    ) : null;
  };

  if (loadingSegments) {
    return <Wait loader="dots">Loading segments ...</Wait>;
  }

  return (
    <MantineProvider theme={theme}>
      <Box w="100%" className="segmentEditor">
        {saving && <Wait loader="dots">Saving segments ...</Wait>}
        <EditorDashboard
          onSaveSegments={saveAllSegments}
          segmentsCount={{
            total: allSegmentsHashes.length,
            filtered: filteredSegments.length,
          }}
          SearchBarAndFilterMenu={
            <SearchSegments
              filterCount={(filters: typeof filterBy) =>
                filterSegments(
                  segmentsObj,
                  segmentsHashes,
                  filters,
                  searchBy,
                  segmentsAttributes
                ).length
              }
              setSearchBy={setSearchBy}
              searchBy={searchBy}
            />
          }
          SortByButtonsAndMenu={
            <SortSegments setSortBy={setSortBy} sortBy={sortBy} />
          }
        />
        <SegmentsHeader target={target} />
        {showUnsavedModal && (
          <UnsavedChangesModal
            open={true}
            onCancel={closeModal}
            onSave={handleSave}
            onDiscard={handleDiscard}
          />
        )}
        {isInScope ? (
          <Virtuoso
            tabIndex={undefined}
            ref={listRef}
            computeItemKey={(index: number) =>
              segmentForIndex(index)?.segment_hash
            }
            style={{
              height: listHeight,
            }}
            totalCount={sortedSegments.length}
            itemContent={(index: number) => <SegmentRow index={index} />}
            // components={{ Item }}
          />
        ) : (
          <Flex className={classes.outOfScope}>
            <AlertIcon />
            <Text fw={600}>This page is out of scope.</Text>
          </Flex>
        )}
      </Box>
    </MantineProvider>
  );
}

export const SegmentEditor = forwardRef(SegmentEditorInternal);
