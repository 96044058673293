import getUserKeyAndToken from './getUserKeyAndToken';
import { ResponseType } from './Fetcher';
import { AxiosRequestConfig } from 'axios';
import { JSONValue } from 'helpers';

/**
 *
 * @param endpoint
 * @param params
 * @param signal
 * @param responseType
 * @param data - NOTE: If you wish to pass data, such as with a POST, it's usually done with the 2nd arg of
 *               axios.post(...), NOT in the config. This data variable is used for exceptions, such as including data
 *               for a GET or a DELETE.
 * @return { url: string, config: AxiosRequestConfig }
 */
const getAxiosUrlAndConfig = (
  endpoint: string,
  params: object = {},
  signal?: AbortSignal,
  responseType?: ResponseType,
  data?: JSONValue
) => {
  const { baseUrl, userKey = '', token } = getUserKeyAndToken();

  const url = `${baseUrl}/${endpoint}`;
  const config: AxiosRequestConfig = {
    headers: {
      Authorization: token,
    },
    params: {
      user_key: userKey || '',
      ...params,
    },
    maxContentLength: Infinity, // NOTE: XAPIS will return responses larger than 5MB, keep this
    maxBodyLength: Infinity, // NOTE: XAPIS will return responses larger than 5MB, keep this
    ...(data && { data }),
    ...(responseType && { responseType }),
    ...(signal && { signal }),
  };

  return { url, config };
};

export default getAxiosUrlAndConfig;
